import React from 'react';
import classNames from 'classnames';
import './index.css';

const Banner = ({ cta = false, fullWidth = false }) => {
    return (
        <div className={classNames({ container: !fullWidth })}>
            <div className="top-description">
                <div className="container">
                    <div className="top-description-left">
                        <p>Ne-am gândit să venim în ajutorul clienților/partenerilor noștri, așa că la fiecare ședință de curățenie generală oferim CADOU curățarea, igienizarea și reîmprospătarea a două obiecte textile (la alegerea clientului)!</p>
                        <p>Ca să nu fie “presiunea” prea mare, oferta este valabilă pe tot parcursul acestuia an!</p>
                    </div>
                    <div className="top-description-right">
                        {cta && (
                            <a href="tel:0745304481" className="btn secondary"><span className="ion ion-logo-whatsapp"></span>(0745) 304 481</a>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner;
import React from 'react';
import './index.css';

const TopBar = () => {
    return (
        <div class="topBar">
            <div class="container">
                <ul className="topBar-content">
                    <li><a href="mailto:office@purifio.ro"><span className="ion ion-ios-mail"></span> office@purifio.ro</a></li>
                    <li><a href="tel:0745304481"><span className="ion ion-ios-call"></span> (0745) 304 481</a></li>
                    <li><a href="https://www.instagram.com/purifio.ro/" target="_blank"><span className="ion ion-logo-instagram"></span> Instagram</a></li>
                    <li><a href="https://www.facebook.com/purifio/" target="_blank"><span className="ion ion-logo-facebook"></span> Facebook</a></li>
                </ul>
            </div>
        </div>
    );
};

export default TopBar;
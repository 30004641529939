import React from 'react';
import logo from '../../logo-white.svg';

import './index.css';

const Footer = () => {
    const yearNow = new Date().getUTCFullYear();
    return (
        <footer className="footer">
            <div className="container">
                <div>
                    <img src={logo} className="footer-logo" alt="logo" />
                    <div className="socials">
                        <a href="mailto:office@purifio.ro"><span className="ion ion-ios-mail"></span> office@purifio.ro</a>
                        <a href="tel:0745304481"><span className="ion ion-ios-call"></span> (0745) 304 481</a>
                        <a href="https://www.instagram.com/purifio.ro/" target="_blank"><span className="ion ion-logo-instagram"></span> Instagram</a>
                        <a href="https://www.facebook.com/purifio/" target="_blank"><span className="ion ion-logo-facebook"></span> Facebook</a>
                    </div>
                </div>
                <div className="copyright">
                    site design / logo &copy; {yearNow} Purifio SRL. CUI: 41588200, Reg. Com. J12/3455/2019
                </div>
            </div>
        </footer>
    )
}

export default Footer;
import React from "react";
import Header from "./parts/Header";
import Footer from "./parts/Footer";
import Feature from "./components/Feature";
import Banner from "./components/Banner";
import TopBar from "./components/TopBar";

import './App.css';

const App = () => {
	return (
		<div className="App">
			<TopBar />
			<Header />
			<main>
				<Banner cta={true} fullWidth={true} />
				<div className="container">
					<div className="restricted-container">
						<h2>Despre <span>noi</span></h2>
						<p>Curățăm cu drag și spor <strong>ORICE</strong> spațiu care are nevoie de îngrijiri profesionale!</p>
						<p>Obiectivul nostru principal, este de a oferi clienților/partenerilor Purifio servicii de curățenie impecabile și de calitate. Acest lucru înseamnă că păstrăm numărul de clienți/lucrări la un maxim stabilit, astfel încât să putem transforma orice spațiu impur într-unul <em>mai curat, mai sănătos!</em></p>
						<p>Suntem foarte exigenți, punctuali și atenți la fiecare detaliu, tocmai din acest motiv asigurăm orice tip de curățenie aveți nevoie (de întreținere, generală sau după constructor) și indiferent de suprafața spațiului, frecvența intervențiilor sau gradul de dificultate, <a href="https://www.instagram.com/purifio.ro/" className="link" target="_blank">#echipaPurifio</a> oferă mereu servicii premium.</p>
					</div>
				</div>
				<div className="feature-bg">
					<div className="container">
						<h2>Serviciile <span>Noastre</span></h2>
						<div className="feature-container">
							<Feature classNM="service-1" img={"https://purifio.ro/media/servicii/curatam-dupa-constructor.jpg"} title="Curățenie după constructor" content="Segmentul rezidențial / industrial / comercial" />
							<Feature classNM="service-2" img={"https://purifio.ro/media/servicii/curatenie-rezidentiala.jpg"} title="Curățenie rezidențială / indistrială / comercială" content="Generală / întreținere (cu frecvență zilnică, săptămâna sau lunară, curățenie de sărbători etc)" />
							<Feature classNM="service-3" img={"https://purifio.ro/media/servicii/curatenie-scari.jpg"} title="Curățenie scări de bloc" content="După constructor / generală / întreținere " />
							
							<Feature classNM="service-4" img={"https://purifio.ro/media/servicii/curatenie-birouri.jpg"} title="Curățenie birouri" content="După constructor / generală / întreținere" />
							<Feature classNM="service-5" img={"https://purifio.ro/media/servicii/curatam-suprafete-textile.jpg"} title="Curățare suprafețe textile" content="Tapiserii auto / mochete / covoare " />
							<Feature classNM="service-6" img={"https://purifio.ro/media/servicii/aprovizionare-consumabile.jpg"} title="Aprovizionare consumabile" content="Dispensere de săpun / uscătoare de mâini etc." />
						</div>
					</div>
				</div>
			</main>
			<Footer />
		</div>
	);
}

export default App;

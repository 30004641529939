import React from 'react';
import logo from '../../logo.svg';
import Form from '../../components/Form';

import './index.css';

const Header = () => {
    return (
        <header className="header">
            <div className="container">
                <div className="logo-container">
                    <img src={logo} className="logo" alt="logo" />
                    <p>Mai curat, mai sănătos!</p>
                    <hr></hr>
                    <p>Servicii profesionale de curățenie</p>
                </div>
            </div>
        </header>
    )
}

export default Header;
import React from 'react';
import classNames from 'classnames';
import './index.css';

const Feature = ({ img = "", title = "", content = "", classNM = "" }) => {
    return (
        <div className={classNames("feature", classNM )} style={{ backgroundImage: `url(${img})` }}>
            <div>
                <p>{title}</p>
                <p className="feature-content">{content}</p>
            </div>
        </div>
    )
}

export default Feature;